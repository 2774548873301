import type { PostItem } from "./useCms";

export const usePosts = () => {
  const { useItems } = useCms();
  const { readItems } = useItems();

  /**
   * Default posts object for reactive properties initialization
   */
  const defaultPosts = {
    data: [] as PostItem[],
    meta: { total_count: 0, filter_count: 0 },
  };

  /**
   * Post field presets
   */
  const postFieldPresets: Record<string, string[]> = {
    listing: [
      "id",
      "type",
      "title",
      "slug",
      "subtitle_type",
      "subtitle",
      "subtitle_label",
      "architect_name",
      "architects",
      "cover.*.*",
      "logo.*",
      "tags.sort",
      "tags.tags_id.title",
      "location_map_enabled",
      "location_map_marker",
      "date_published",
      "strategic",
      "position",

      "parent_post.type",
      "parent_post.slug",
      "parent_post.title",
      "parent_post.subtitle",
      "parent_post.logo.*.*",
    ],

    content: [
      "*",

      "tags.*.*",
      "logo.*",

      "cover.*.*",

      "content.id",
      "content.sort",
      "content.collection",
      "content.item.*.*",

      "author.*.*",

      "images.*.*.*",
      "videos.*.*.*.*",

      "related.*.*",
      "related.*.cover.*.*",
      "related.*.logo.*.*",
      "related.*.tags.sort",
      "related.*.tags.tags_id.title",

      "related.*.parent_post.type",
      "related.*.parent_post.slug",
      "related.*.parent_post.title",
      "related.*.parent_post.subtitle",
      "related.*.parent_post.logo.*.*",

      "parent_post.type",
      "parent_post.slug",
      "parent_post.title",
      "parent_post.subtitle",
      "parent_post.logo.*.*",
    ],
  };

  /**
   * Get post by slug
   * @param {MaybeRef<string>} slug the post slug
   * @param {Object} options the options object
   * @param {MaybeRef<PostType> | undefined} options.type the post type
   * @param {MaybeRef<string[]> | undefined} options.fields the fields to get
   * @returns {Promise<PostItem | null>} the post
   */
  const getPost = async (
    slug: MaybeRef<string>,
    options: {
      type?: MaybeRef<PostType>;
      fields?: MaybeRef<string[]>;
    } = {},
    token?: MaybeRef<string | undefined>
  ): Promise<PostItem | null> => {
    const type = unref(options.type) || undefined;
    const fields = unref(options.fields) || [];

    const filter = { slug: { _eq: unref(slug) }, type: { _eq: type } };
    const limit = 1;

    const access_token = unref(token);

    return await readItems("posts", {
      filter,
      fields,
      limit,
      ...(access_token ? { access_token } : {}),
    }).then((response) => {
      return response?.[0] || null;
    });
  };

  /**
   * Get posts
   * @param {Object} options the options object
   * @param {MaybeRef<PostType> | undefined} options.type the post type
   * @param {MaybeRef<number> | undefined} options.page the page number
   * @param {MaybeRef<number> | undefined} options.limit the limit
   * @param {MaybeRef<string> | undefined} options.sort the sort
   * @param {MaybeRef<string[]> | undefined} options.fields the fields to get
   * @returns {Promise<{ data: PostItem[]; meta: { total_count: number; filter_count: number }; }>} the posts
   */
  const getPosts = async (
    options: {
      type?: MaybeRef<PostType>;
      page?: MaybeRef<number>;
      limit?: MaybeRef<number>;
      sort?: MaybeRef<string>;
      fields?: MaybeRef<string[]>;
      filter?: MaybeRef<Record<string, unknown>>;
    } = {}
  ): Promise<{
    data: PostItem[];
    meta: { total_count: number; filter_count: number };
  }> => {
    const type = unref(options.type) || undefined;
    const page = unref(options.page) || 1;
    const limit = unref(options.limit) || 10;
    const fields = unref(options.fields) || [];

    const sort = unref(options.sort) || "-date_published";

    const filter: any = {
      _and: [
        { type: { _eq: type } },
        { status: { _eq: "published" } },
        { date_published: { _lte: new Date().toISOString() } },
        unref(options.filter),
      ].filter(Boolean),
    };

    const total_count = await readItems("posts", {
      aggregate: { countDistinct: ["id"] },
    } as any)
      .then((res) => Number(res?.[0]?.countDistinct?.id || 0))
      .catch(() => 0);

    const filter_count = await readItems("posts", {
      filter,
      aggregate: { countDistinct: ["id"] },
    } as any)
      .then((res) => Number(res?.[0]?.countDistinct?.id || 0))
      .catch(() => 0);

    const data = await readItems("posts", {
      filter,
      fields,
      page,
      limit,
      sort,
    })
      .then((response) => response || [])
      .catch(() => []);

    const meta = { total_count, filter_count };

    return { data, meta };
  };

  /**
   * Get adjacent post
   * @param {MaybeRef<"next" | "prev">} direction the direction
   * @param {MaybeRef<PostItem>} post the post to get adjacent post from
   * @param {Object} options the options object
   * @param {MaybeRef<PostType> | undefined} options.type the post type
   * @param {MaybeRef<string[]> | undefined} options.fields the fields to get
   * @returns {Promise<PostItem | null>} the adjacent post
   */
  const getAdjacentPost = async (
    direction: MaybeRef<"next" | "prev">,
    post: MaybeRef<PostItem>,
    options: {
      type?: MaybeRef<PostType>;
      fields?: MaybeRef<string[]>;
    } = {}
  ): Promise<PostItem | null> => {
    const type = unref(options.type) || undefined;
    const fields = unref(options.fields) || ["*"];

    const date = unref(post)?.date_published;

    const filter = {
      ...(type ? { type: { _eq: type } } : {}),
      date_published: {
        [direction === "prev" ? `_lt` : "_gt"]: date,
      },
    };

    const sort = direction === "prev" ? "-date_published" : "date_published";

    return await readItems("posts", {
      filter,
      fields,
      limit: 1,
      sort,
    })
      .then((response: PostItem[]) => response?.[0] || null)
      .catch(() => null);
  };

  /**
   * Post type labels
   */
  const postTypeLabels = {
    [PostType.Inspiration]: "Inspiracja",
    [PostType.Professional]: "Partner",
    [PostType.Architect]: "Architekt",
    [PostType.Relaxation]: "Relaks",
    [PostType.Contractor]: "Wykonawca",
    [PostType.Project]: "Gotowy projekt",
  };

  /**
   * Get post type label
   * @param {MaybeRef<PostType>} type the post type
   * @returns {string} the post type label
   */
  const getPostTypeLabel = (type: MaybeRef<PostType>): string => {
    return postTypeLabels[unref(type)];
  };

  const localePath = useLocalePath();
  const { getPostTypeSlug } = usePostTypes();

  /**
   * Get post link
   * @param {MaybeRef<PostType>} type the post type
   * @param {MaybeRef<string>} slug the post slug
   * @returns {string} the post link
   */
  const getPostLink = (
    type: MaybeRef<PostType>,
    slug: MaybeRef<string>
  ): string => {
    const typeSlug = getPostTypeSlug(unref(type));

    return localePath({
      name: "type-slug",
      params: { type: typeSlug, slug: unref(slug) },
    });
  };

  return {
    defaultPosts,
    postFieldPresets,

    getPost,
    getPosts,

    getAdjacentPost,

    getPostTypeLabel,

    getPostLink,
  };
};
